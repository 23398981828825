import { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

import { useEnv } from '@zenbusiness/application-commons-env';
import { useIdentity } from '@zenbusiness/application-commons-identity';
import AppBar from '@zenbusiness/zui/AppBar';
import Box from '@zenbusiness/zui/Box';
import Button from '@zenbusiness/zui/Button';
import Collapse from '@zenbusiness/zui/Collapse';
import Container from '@zenbusiness/zui/Container';
import Divider from '@zenbusiness/zui/Divider';
import Drawer from '@zenbusiness/zui/Drawer';
import Heading from '@zenbusiness/zui/Heading';
import IconButton from '@zenbusiness/zui/IconButton';
import List from '@zenbusiness/zui/List';
import ListItem from '@zenbusiness/zui/ListItem';
import ListItemButton from '@zenbusiness/zui/ListItemButton';
import ListItemText from '@zenbusiness/zui/ListItemText';
import SharedAsset from '@zenbusiness/zui/SharedAsset';
import Stack from '@zenbusiness/zui/Stack';
import { superstyled } from '@zenbusiness/zui/styles';
import Text from '@zenbusiness/zui/Text';
import Toolbar from '@zenbusiness/zui/Toolbar';

import NavItem from './NavItem';

const ZBLogoIcon = superstyled({
  tag: SharedAsset,
  name: 'ZBLogoIcon',
  propsAdded: {
    src: '/logo/circle-logo-teal.svg',
    width: 30
  },
  styles: ({ theme }) => ({
    marginRight: theme.spacing(1)
  })
});

export const hasPermission = (permissions, ...requiredPermissions) => (
  requiredPermissions?.some((permission) => permissions?.has(permission))
);

export const hasRecommendationToolPermission = (identified, permissions, deployEnv) => (
  identified && deployEnv !== 'production' && (
    hasPermission(permissions, 'oar:admin', 'oar:product', 'oar:dev')
  )
);

export const hasFintechToolPermission = (identified, permissions) => (
  identified && hasPermission(
    permissions,
    'faui:eng',
    'faui:admin',
    'faui:cs',
    'access:developers',
    'access:internal'
  )
);

export const hasLLMAdminToolPermission = (identified, permissions) => (
  identified && hasPermission(
    permissions,
    'llm:eng',
    'llm:admin',
    'access:developers',
    'access:internal'
  )
);

export const hasJourneysAdminPermission = (identified, permissions) => (
  identified && hasPermission(
    permissions,
    'access:developers'
  )
);

function generateSharedZGSitemap(options) {
  const { permissions, identified, DEPLOY_ENV } = options;
  const hasDeveloperPermission = hasPermission(permissions, 'access:developers');
  const hasEcatAdminPermission = hasPermission(permissions, 'ecat:admin');
  const isRecommendationToolVisible = hasRecommendationToolPermission(
    identified,
    permissions,
    DEPLOY_ENV
  );
  const isFintechToolVisible = hasFintechToolPermission(identified, permissions);
  const isLLMAdminToolVisible = hasLLMAdminToolPermission(identified, permissions);
  const isJourneysAdminVisible = hasJourneysAdminPermission(identified, permissions);

  const isToolsVisible = isRecommendationToolVisible
    || isFintechToolVisible
    || isLLMAdminToolVisible
    || isJourneysAdminVisible;

  /**
   * The sitemap for the entire ZG product, composed of the routes handled by Admin-UI and ZenGarden services.
   */
  const sharedZGSitemap = [
    {
      key: 'accounts',
      label: 'Accounts',
      href: '/account'
    },
    {
      key: 'orders',
      label: 'Orders',
      href: '/order'
    },
    {
      key: 'business-entities',
      label: 'Business Entities',
      href: '/admin/business-entity'
    },
    {
      key: 'developers',
      label: 'Developers',
      display: hasDeveloperPermission,
      children: [
        {
          label: 'ZenAPI Specification',
          href: '/catalog/default/api/zenapi/definition'
        },
        {
          label: 'Data Alerts',
          href: '/developers/data-alerts'
        },
        {
          label: 'Order creation error handling',
          href: '/developers/retry-order-creation'
        }
      ]
    },
    {
      key: 'products',
      label: 'Products & Pricing',
      children: [
        {
          label: 'Price List Manager',
          href: '/ecomm/price-lists',
          display: hasEcatAdminPermission
        },
        {
          label: 'Manage Affiliates',
          href: '/products/create-new-affiliate'
        },
        {
          label: 'Affiliate Pricing',
          href: '/products/affiliate-pricing'
        },
        {
          label: 'Coupons',
          href: '/products/coupons'
        },
        {
          label: 'Affiliate Plans',
          href: '/products/affiliate-plan-options'
        },
        {
          label: 'Plans',
          href: '/products/plan-options'
        },
        {
          label: 'Services C-Corp',
          href: '/products/services-c-corp'
        },
        {
          label: 'Services Fulfillment',
          href: '/products/services-fulfillment'
        },
        {
          label: 'Service Fees',
          href: '/products/service-fees'
        },
        {
          label: 'Service Pricing',
          href: '/products/service-pricing'
        },
        {
          label: 'Service Products',
          href: '/products/service-products'
        },
        {
          label: 'Amendments & Annual Reports Configuration',
          href: '/products/amendment-report-field-config'
        }
      ]
    },
    {
      key: 'automation',
      label: 'Automation',
      children: [
        {
          label: 'Automation Status',
          href: '/automation/automation-status'
        },
        {
          label: 'Automated Filings',
          href: '/automation/automation-filings'
        },
        {
          label: 'Generate Payload',
          href: '/automation/generate-payload'
        },
        {
          label: 'CA Login Access Queue',
          href: '/automation/ca-login-access-queue'
        }
      ]
    },
    {
      key: 'zebra',
      label: 'ZeBRA',
      href: '/zebra'
    },
    {
      key: 'tools',
      label: 'Tools',
      display: isToolsVisible,
      children: [
        {
          label: 'Recommendation Admin Tool',
          href: '/mfe/recommendation-admin',
          display: isRecommendationToolVisible
        },
        {
          label: 'Fintech Admin Tool',
          href: '/mfe/fintech-admin/admin',
          display: isFintechToolVisible
        },
        {
          label: 'LLM Admin Tool',
          href: '/llm/admin',
          display: isLLMAdminToolVisible
        },
        {
          label: 'Journeys Admin Tool',
          href: '/mfe/journeys-admin-ui',
          display: isJourneysAdminVisible
        }
      ]
    }
  ];

  return sharedZGSitemap;
}

const ZenGardenLogo = () => (
  <Stack direction="row" alignItems="center" sx={{ flexGrow: { xs: 1, xl: 0 }, justifyContent: { xs: 'center', xl: 'start' } }}>
    <ZBLogoIcon alt="ZenBusiness Logo" />
    <Heading component="h1" size="xl" color="primary">ZenGarden</Heading>
  </Stack>
);

export default function TopNav() {
  const {
    authenticated, identified, firstName, permissions
  } = useIdentity();
  const { DEPLOY_ENV } = useEnv();
  const [menuOpen, setMenuOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState({});

  const handleDrawerToggle = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const handleSubMenuOpen = (page) => () => {
    const { key } = page;
    const currentValue = subMenuOpen[key] ?? false;
    setSubMenuOpen({
      ...subMenuOpen,
      [key]: !currentValue
    });
  };

  const sharedZGSitemap = generateSharedZGSitemap({ permissions, identified, DEPLOY_ENV });

  const displayable = (item) => (item?.display ?? true);

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: 'background.grey.dark' }}>
        <Container maxWidth="false">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 0, display: { xs: 'flex', xl: 'none' } }}>
              <IconButton
                size="large"
                label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <ZenGardenLogo />
            {/* XL Window Size Menu */}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', xl: 'flex' } }}>
              {sharedZGSitemap.filter(displayable).map((page) => (
                <NavItem key={page.key} label={page.label} href={page.href}>
                  {page.children && page.children.filter(displayable).map((child) => (
                    <NavItem key={child.key || child.label} label={child.label} href={child.href} />
                  ))}
                </NavItem>
              ))}
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: 'none', xl: 'flex' }, alignItems: 'center' }}>
              {identified && <Text sx={{ mx: 2 }}>{firstName}</Text>}
              <Button component={Link} size="sm" to={authenticated ? '/logout' : '/login'}>
                {authenticated ? 'Logout' : 'Login'}
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <nav>
        <Drawer
          // container={container}
          variant="temporary"
          open={menuOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', xl: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 }
          }}
        >
          {/* onClick={handleDrawerToggle} */}
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ my: 2 }}>
              <ZenGardenLogo />
            </Box>
            <Divider />
            <List>
              {sharedZGSitemap.filter(displayable).map((page) => (
                <Box key={page.key}>
                  <ListItem disablePadding>
                    <ListItemButton href={page.href} onClick={handleSubMenuOpen(page)}>
                      <ListItemText primary={page.label} />
                      {page.children && (subMenuOpen[page.key] ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      ))}
                    </ListItemButton>
                  </ListItem>
                  <Collapse in={subMenuOpen[page.key]} timeout="auto" unmountOnExit>
                    {page.children && page.children.filter(displayable).map((child) => (
                      <ListItem sx={{ ml: 2 }} key={child.key || child.label} disablePadding>
                        <ListItemButton href={child.href}>
                          <ListItemText primary={child.label} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </Collapse>
                </Box>
              ))}
            </List>
            <Divider />
            <Box
              sx={{
                flexGrow: 0,
                display: { xs: 'flex', xl: 'none' },
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center'
              }}
              mt={2}
            >
              {identified && <Text sx={{ mx: 2 }}>{firstName}</Text>}
              <Button component={Link} size="sm" to={authenticated ? '/logout' : '/login'}>
                {authenticated ? 'Logout' : 'Login'}
              </Button>
            </Box>
          </Box>
        </Drawer>
      </nav>
    </>
  );
}
